export const TrabajosItems = [
  {
    title: "Trabajo 1",
    src: "images/trabajos/trabajo_1.JPG",
  },
  {
    title: "Instalación de ladrillo refractario en horno.",
    src: "images/trabajos/trabajo_2.JPG",
  },
  {
    title: "Demolición",
    src: "images/trabajos/trabajo_4.jpeg",
  },
   {
    title: "Trabajo 3",
    src: "images/trabajos/trabajo_3.JPG",
  },
]
